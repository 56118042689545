<template>
  <span v-if="value" class="date">{{ formatDate(value) }}</span>
  <span v-else>
    <slot>
      <span class="date empty">NULL</span>
    </slot>
  </span>
</template>

<script>
export default {
  props: ['value']
};
</script>
