





























































import Vue from 'vue';

type Data = {
  dialog: boolean,
  error: Error | null,
  attrs: {
    name: string | null;
    email: string | null;
    roles: string[];
  }
};

export default Vue.extend({
  data(): Data {
    return {
      dialog: false,
      error: null,
      attrs: {
        name: null,
        email: null,
        roles: []
      }
    };
  },
  methods: {
    save() {
      const { name, email, roles } = this.attrs;
      this.startSaving();
      this.$store.api.createUser({
        name,
        email,
        roles
      }).then(() => {
        this.$store.change();
        this.closeDialog();
      }).catch(this.setError).then(this.stopSaving);
    },
    closeDialog() {
      this.dialog = false;
      this.restoreInitialState();
    }
  }
});
