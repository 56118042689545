






























































import Vue, { PropType } from 'vue';
import { Contract } from '@/types';

type Data = {
  contract: Contract | null,
  overrideIssueDate: string | null,
  overrideExpirationDate: string | null,
  licenseToken: string | null,
  dialog: boolean
};

export default Vue.extend({
  props: {
    contract_id: {
      type: Number as PropType<number>,
      required: true
    }
  },
  data(): Data {
    return {
      contract: null,
      overrideIssueDate: null,
      overrideExpirationDate: null,
      licenseToken: null,
      dialog: false
    };
  },
  watch: {
    contract_id() {
      this.load();
    },
    dialog(v) {
      if (v === false) {
        this.restoreInitialState();
      } else {
        this.load();
      }
    }
  },
  methods: {
    load() {
      this.$store.api.contract(this.contract_id).then(contract => {
        this.contract = contract;
      }).catch(this.setError);
    },
    generate() {
      const { contract_id, overrideExpirationDate, overrideIssueDate } = this;
      this.cleanError();
      this.licenseToken = null;
      this.$store.api.getContractLicense({
        contract_id,
        overrideIssueDate: overrideIssueDate || undefined,
        overrideExpirationDate: overrideExpirationDate || undefined
      }).then((token) => {
        this.licenseToken = token;
      }).catch(this.setError);
    }
  },
  mounted() {
    this.load();
  }
});
