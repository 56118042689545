

























































































import Vue, { PropType } from 'vue';
import { parseDate, parseDateIfNotEmpty } from '@/helpers';
import {Customer, LICENSE_TYPE} from '@/types';
import JsonArea from '@/components/controls/JsonArea.vue';

type Data = {
  dialog: boolean;
  error: Error | null;
  customer: Customer | null;
  attrs: {
    customer_id: number | null;
    comment: string | null;
    issue_date: string | null;
    expiration_date: string | null;
    warning_date: string | null;
    warning_text: string | null;
    license_ttl: number | null;
    license_type?: LICENSE_TYPE;
    hw?: string | null;
    f?: Record<string, string> | null;
  }
};

export default Vue.extend({
  components: { JsonArea },
  props: {
    customer_id: {
      type: Number as PropType<number>
    }
  },
  data(): Data {
    return {
      dialog: false,
      error: null,
      customer: null,
      attrs: {
        customer_id: null,
        comment: '',
        issue_date: null,
        expiration_date: null,
        warning_date: null,
        warning_text: null,
        license_ttl: 86400,
        hw: null,
        f: null
      }
    };
  },
  watch: {
    dialog(v) {
      if (v) {
        this.loadCustomer();
      } else {
        this.cleanError();
        this.restoreInitialState();
      }
    }
  },
  methods: {
    save() {
      const {
        customer_id,
        comment,
        issue_date,
        expiration_date,
        warning_date,
        warning_text,
        license_ttl,
        license_type,
        hw,
        f
      } = this.attrs;
      this.startSaving();
      this.$store.api.createContract({
        customer_id: this.customer_id || Number(customer_id),
        comment,
        issue_date: issue_date ? parseDate(issue_date) : undefined,
        expiration_date: expiration_date ? parseDate(expiration_date) : null,
        warning_date: parseDateIfNotEmpty(warning_date),
        warning_text: warning_text,
        license_ttl: Number(license_ttl),
        license_type,
        hw: this.cleanString(hw),
        f: f
      }).then(() => {
        this.$store.change();
        this.closeDialog();
      }).catch(this.setError).then(this.stopSaving);
    },
    cleanString(str: string | null | undefined) {
      if (!str) {
        return null;
      }
      str = str.trim();
      return str.length > 0 ? str : null;
    },
    closeDialog() {
      this.dialog = false;
    },
    loadCustomer() {
      if (!this.customer_id) {
        return;
      }

      this.$store.api.customer(this.customer_id).then(customer => {
        this.customer = customer;
      }).catch(this.setError);
    }
  }
});
