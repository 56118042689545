var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('v-col',{staticClass:"mb-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('store-alert'),_c('div',{staticClass:"table-container"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":0,"expanded":_vm.expanded,"footer-props":_vm.defaultFooterProps,"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.isLoading,"show-expand":"","fixed-header":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":[function($event){_vm.options=$event},_vm.updateOptions]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"panel"},[_c('div',{staticClass:"title"},[_c('v-icon',[_vm._v("mdi-key")]),_vm._v(" Ip Stats ")],1),_c('div',{staticClass:"spacer"}),_c('query-filters'),_c('ips-filter')],1)]},proxy:true},{key:"item.customers",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"row-gap-4"},_vm._l((item.customers),function(c,i){return _c('customer-chip',{key:i,attrs:{"customer":c}})}),1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"whois"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.loadWhois(item.id)}}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_vm._v(" "+_vm._s(item.whois || 'Not resolved yet')+" ")],1)])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }