


























































import Vue from 'vue';
import StoreAlert from '@/components/StoreAlert.vue';
import {ContractMail, SortOption, TableData, TableOptions} from '@/types';

function defaultOptions(): TableOptions {
  return {
    sortBy: ['id'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 20
  };
}

export default Vue.extend({
  name: 'LicenseMails',
  components: { StoreAlert },
  data(): TableData<ContractMail> {
    return {
      headers: [
        { value: 'id', text: 'ID' },
        { value: 'contract', text: 'Contract' },
        { value: 'email', text: 'Email' },
        { value: 'subject', text: 'Subject' },
        { value: 'sent_at', text: 'Sent at' },
        { value: 'error', text: 'Error' },
        { value: 'created_at', text: 'Created at' },
        { value: 'updated_at', text: 'Updated at' }
      ],
      items: [],
      options: defaultOptions(),
      expanded: [],
      total: 0
    };
  },
  watch: {
    $hash() {
      this.$store.updateTableOptions(this.options, defaultOptions());
      this.load();
    }
  },
  computed: {
    sort(): SortOption[] {
      const { sortBy, sortDesc } = this.options;
      return (sortBy as string[]).map((column, i) => {
        return {
          column,
          order: sortDesc[i] ? 'desc' : 'asc'
        };
      });
    }
  },
  methods: {
    updateOptions(options: TableOptions) {
      this.$store.setTableOptions(options, defaultOptions());
    },
    load() {
      this.cleanError();
      this.startLoading();
      const { page, itemsPerPage } = this.options;
      return this.$store.api.contractMails({
        where: this.$store.query,
        page,
        sort: this.sort,
        perPage: itemsPerPage
      }).then(({ items, total }) => {
        this.stopLoading();
        this.items = items;
        this.total = total;
      }).catch(this.setError);
    }
  },
  created() {
    this.$store.updateTableOptions(this.options, defaultOptions());
    this.load();
  }
});
