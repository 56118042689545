




















































import Vue from 'vue';

type Data = {
  dialog: boolean,
  error: Error | null,
  attrs: {
    name: string | null;
    company_name?: string | null;
    email: string | null;
    comment?: string | null;
    expiration_text?: string | null;
  }
};

export default Vue.extend({
  data(): Data {
    return {
      dialog: false,
      error: null,
      attrs: {
        name: null,
        company_name: undefined,
        email: null,
        comment: null,
        expiration_text: null
      }
    };
  },
  methods: {
    save() {
      const { name, company_name, email, comment, expiration_text } = this.attrs;
      this.startSaving();
      this.$store.api.createCustomer({
        name,
        company_name,
        email,
        comment: comment || null,
        expiration_text: expiration_text || null
      }).then(() => {
        this.$store.change();
        this.closeDialog();
      }).catch(this.setError).then(this.stopSaving);
    },
    closeDialog() {
      this.dialog = false;
      this.restoreInitialState();
    }
  }
});
