




































import Vue, {PropType} from 'vue';
import {User} from '@/types';

export default Vue.extend({
  components: {},
  props: {
    id: {
      type: Number as PropType<number>,
      required: true
    }
  },
  data(): { user: User | null } {
    return {
      user: null
    };
  },
  watch: {
    $hash() {
      this.load();
    }
  },
  methods: {
    load() {
      this.$store.api.user(this.id).then(user => {
        this.user = user;
      }).catch(this.setError);
    }
  },
  mounted() {
    this.load();
  }
});
