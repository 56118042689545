<template>
  <layout>
    <v-textarea v-model="token" label="License token" rows="12" solo spellcheck="false" style="font-family: monospace; white-space: pre"></v-textarea>
    <v-alert v-if="vError" class="mb-2" type="error">
      {{vError}}
    </v-alert>
    <div v-else-if="payload" class="token-payload">{{payload}}</div>
    <div v-if="payload">
      <div>Issue date: {{(secondsToIsoDate(payload.v))}}</div>
      <div>Expiration date: {{(secondsToIsoDate(payload.e))}}</div>
    </div>
    <v-btn :color="btnColor" dark @click="verify">Verify</v-btn>
  </layout>
</template>

<script>
export default {
  data() {
    return {
      token: '',
      payload: null
    };
  },
  computed: {
    btnColor() {
      if (this.vError) {
        return 'error';
      }

      return this.payload ? 'light-green' : 'primary';
    }
  },
  methods: {
    verify() {
      this.cleanError();
      this.payload = null;
      this.$store.api.verify(this.token).then(payload => {
        this.payload = payload;
      }).catch(this.setError);
    }
  }
};
</script>
