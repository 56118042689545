<template>
  <layout>
    <div class="d-flex row">
      <v-card class="info-card">
        <v-card-title>Servers</v-card-title>
        <v-card-text>
          <div v-for="(app, i) in apps" :key="i" class="dict">
            <div v-for="(v, k) in app.state" :key="k">
              <div class="key">{{k}}</div>
              <div class="value">{{v}}</div>
            </div>
          </div>
        </v-card-text>
        <v-btn class="refresh" icon @click="load">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-card>
    </div>
  </layout>
</template>
<script>
export default {
  data() {
    return {
      apps: []
    };
  },
  methods: {
    load() {
      this.$store.api.appsStatus().then(r => {
        this.apps = r;
      }).catch(this.setError);
    }
  },
  mounted() {
    this.load();
  }
};
</script>
