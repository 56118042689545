<template>
  <div>
    <v-textarea
      v-bind="$attrs"
      v-bind:value="localValue"
      v-bind:error-messages="localErrors"
      v-on:input="onInput"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: ['value', 'errorMessages'],
  data() {
    return {
      local: null,
      localValue: null,
      error: null
    };
  },
  watch: {
    value: {
      handler(v) {
        if (JSON.stringify(v) !== JSON.stringify(this.local)) {
          this.local = v;
          this.localValue = JSON.stringify(this.local, null, 2);
        }
      },
      immediate: true
    }
  },
  computed: {
    localErrors() {
      const pe = this.errorMessages || [];
      if (this.error) {
        return [...pe, String(this.error)];
      }
      return pe;
    }
  },
  methods: {
    onInput(v) {
      this.error = null;
      try {
        this.local = v ? JSON.parse(v) : null;
        this.$emit('input', this.local);
      } catch (err) {
        this.error = err;
      }
    }
  }
};
</script>
