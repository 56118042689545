
















import Vue, { PropType } from 'vue';
import { ContractMail } from '@/types';

export default Vue.extend({
  props: {
    contractMail: {
      type: Object as PropType<ContractMail>,
      required: true
    }
  },
  computed: {
    sentAt(): string | null {
      return this.contractMail.sent_at;
    }
  },
  methods: {
    onClick() {
      this.$store.navigate('/contract-mails', {
        id: this.contractMail.id
      });
    }
  }
});
