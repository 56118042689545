




















































import Vue, { PropType } from 'vue';
import { Customer } from '@/types';

type Data = {
  customer: Customer | null;
  dialog: boolean;
  error: Error | null;
};

export default Vue.extend({
  props: {
    customer_id: {
      type: Number as PropType<number>,
      required: true
    }
  },
  data(): Data {
    return {
      customer: null,
      dialog: false,
      error: null
    };
  },
  watch: {
    dialog(v) {
      if (v) {
        this.load();
      }
    }
  },
  methods: {
    load() {
      this.$store.api.customer(this.customer_id).then(customer => {
        this.customer = customer;
      }).catch(this.setError);
    },
    save() {
      const { customer } = this;
      if (!customer) {
        return;
      }
      this.startSaving();
      this.$store.api.editCustomer({
        customer_id: customer.id,
        name: customer.name,
        company_name: customer.company_name,
        comment: customer.comment || null,
        expiration_text: customer.expiration_text || null
      }).then(() => {
        this.$store.change();
        this.dialog = false;
      }).catch(this.setError).then(this.stopSaving);
    }
  }
});
