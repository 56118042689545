




import Vue from 'vue';
import Auth from './pages/Auth.vue';
import RouterView from '@/components/RouterView.vue';
import Logo from '@/components/Logo.vue';
import jwt from 'jsonwebtoken';

export default Vue.extend({
  name: 'App',
  components: {
    Logo,
    Auth,
    RouterView
  },
  computed: {
    logged(): boolean {
      return !!this.$store.token;
    },
    currentPage(): any {
      return this.logged ? RouterView : Auth;
    }
  },
  methods: {
    acquireToken() {
      window.onload = () => {
        // eslint-disable-next-line no-undef
        google.accounts.id.initialize({
          client_id: process.env.VUE_APP_GOOGLE_AUTH_CLIENT_ID,
          callback: (response: any) => {
            this.$store.token = response.credential;
            localStorage.setItem('token', this.$store.token || '');
          }
        });
        // eslint-disable-next-line no-undef
        google.accounts.id.renderButton(
          document.getElementById('auth-div'),
          { theme: 'outline', size: 'large' } // customization attributes
        );
        // eslint-disable-next-line no-undef
        google.accounts.id.prompt();
      };
    }
  },
  mounted() {
    this.$store.token = localStorage.getItem('token');

    const payload = jwt.decode(this.$store.token || '');

    if (payload && typeof payload === 'object') {
      const dt = (payload.exp || 0) - (new Date().getTime() / 1000);

      if (dt <= 0) {
        this.$store.token = null;
      }
    }

    if (!this.$store.token) {
      this.acquireToken();
    }
  }
});
