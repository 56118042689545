























































































































import Vue from 'vue';
import StoreAlert from '@/components/StoreAlert.vue';
import {Contract, SortOption, TableData, TableOptions} from '@/types';
import ContractsFilter from '@/components/filters/ContractsFilter.vue';
import {debounce} from '@/helpers';

function defaultOptions(): TableOptions {
  return {
    sortBy: ['id'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 20
  };
}

export default Vue.extend({
  name: 'Contracts',
  components: { ContractsFilter, StoreAlert },
  data(): TableData<Contract> {
    return {
      headers: [
        { value: 'id', text: 'ID' },
        { value: 'status', text: 'Status', sortable: false },
        { value: 'kind', text: 'Kind' },
        { value: 'customer', text: 'Customer' },
        { value: 'license_type', text: 'License type' },
        { value: 'confirmed_at', text: 'Confirmed at' },
        { value: 'activated_at', text: 'Activated at' },
        { value: 'deactivated_at', text: 'Deactivated at' },
        { value: 'rejected_at', text: 'Rejected at' },
        { value: 'issue_date', text: 'Issue date' },
        { value: 'expiration_date', text: 'Expiration date' },
        { value: 'warning_date', text: 'Warning date' },
        { value: 'created_at', text: 'Created at' },
        { value: 'updated_at', text: 'Updated at' }
      ],
      items: [],
      options: defaultOptions(),
      total: 0,
      expanded: []
    };
  },
  watch: {
    $hash() {
      this.$store.updateTableOptions(this.options, defaultOptions());
      this.load();
    }
  },
  computed: {
    hasFilter(): boolean {
      return Object.keys(this.$store.query).length > 0;
    },
    isCard(): boolean {
      return this.hasFilter && this.items.length === 1;
    },
    sort(): SortOption[] {
      const { sortBy, sortDesc } = this.options;
      return (sortBy as string[]).map((column, i) => {
        return {
          column,
          order: sortDesc[i] ? 'desc' : 'asc'
        };
      });
    }
  },
  methods: {
    itemClass(contract: Contract) {
      if (!contract.activated_at && !contract.rejected_at) {
        return 'contract-row inactive';
      }
      return 'contract-row';
    },
    updateOptions(options: TableOptions) {
      this.$store.setTableOptions(options, defaultOptions());
    },
    load() {
      this.cleanError();
      this.startLoading();
      const { page, itemsPerPage } = this.options;
      this.$store.api.contracts({
        where: this.$store.query,
        sort: this.sort,
        page,
        perPage: itemsPerPage
      }).then(({ items, total }) => {
        this.stopLoading();
        this.items = items;
        this.total = total;
      }).catch(this.setError);
    }
  },
  created() {
    this.$store.updateTableOptions(this.options, defaultOptions());
    this.load = debounce(this.load, 200, true);
    this.load();
  }
});
