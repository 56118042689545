

















































import Vue from 'vue';
import StoreAlert from '@/components/StoreAlert.vue';
import {CustomerEmail, SortOption, TableData, TableOptions} from '@/types';

function defaultOptions(): TableOptions {
  return {
    sortBy: ['id'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 20
  };
}

export default Vue.extend({
  name: 'CustomerEmails',
  components: {StoreAlert},
  data(): TableData<CustomerEmail> {
    return {
      headers: [
        {value: 'id', text: 'ID'},
        {value: 'email', text: 'Email'},
        {value: 'is_academic', text: 'Is academic'},
        {value: 'institution', text: 'Institution'},
        {value: 'verified', text: 'Verified'},
        {value: 'created_at', text: 'Created at'},
        {value: 'updated_at', text: 'Updated at'}
      ],
      items: [],
      options: defaultOptions(),
      expanded: [],
      total: 0
    };
  },
  computed: {
    sort(): SortOption[] {
      const {sortBy, sortDesc} = this.options;
      return (sortBy as string[]).map((column, i) => {
        return {
          column,
          order: sortDesc[i] ? 'desc' : 'asc'
        };
      });
    }
  },
  watch: {
    $hash() {
      this.$store.updateTableOptions(this.options, defaultOptions());
      this.load();
    }
  },
  methods: {
    updateOptions(options: TableOptions) {
      this.$store.setTableOptions(options, defaultOptions());
    },
    load() {
      this.cleanError();
      this.startLoading();
      const {page, itemsPerPage} = this.options;
      const where: Record<string, string | number | boolean> = Object.assign({}, this.$store.query);
      if (where.is_academic) {
        where.is_academic = where.is_academic === 'true';
      }
      return this.$store.api.customerEmails({
        where,
        page,
        sort: this.sort,
        perPage: itemsPerPage
      }).then(({items, total}) => {
        this.stopLoading();
        this.items = items;
        this.total = total;
      }).catch(this.setError);
    }
  },
  created() {
    this.$store.updateTableOptions(this.options, defaultOptions());
    this.load();
  }
});
