

















































import Vue from 'vue';
import { isNumeric } from '@/helpers';

type Data = {
  dialog: boolean,
  error: Error | null
};

export default Vue.extend({
  components: { },
  data(): Data {
    return {
      dialog: false,
      error: null
    };
  },
  computed: {
    filterId: {
      get(): string | null {
        return this.$store.getParam('id');
      },
      set(val: string) {
        if (isNumeric(val)) {
          this.$store.setParam('id', val);
        }
      }
    },
    filterCustomerId: {
      get(): string | null {
        return this.$store.getParam('customer_id');
      },
      set(val: string) {
        this.$store.setParam('customer_id', val);
      }
    },
    filterSearch: {
      get(): string | null {
        return this.$store.getParam('search');
      },
      set(val: string) {
        this.$store.setParam('search', val);
      }
    },
    filterKind: {
      get(): string | null {
        return this.$store.getParam('kind');
      },
      set(val: string) {
        this.$store.setParam('kind', val);
      }
    },
    filterShowUnconfirmed: {
      get(): boolean {
        return this.$store.getParam('show_unconfirmed') === 'true';
      },
      set(val: boolean) {
        this.$store.setParam('show_unconfirmed', val ? 'true' : '');
      }
    }
  },
  methods: {
    save() {
      this.dialog = false;
    }
  }
});
