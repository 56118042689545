














































import Vue, { PropType } from 'vue';
import { User } from '@/types';

type Data = {
  user: User | null;
  dialog: boolean;
  error: Error | null;
};

export default Vue.extend({
  props: {
    user_id: {
      type: Number as PropType<number>,
      required: true
    }
  },
  data(): Data {
    return {
      user: null,
      dialog: false,
      error: null
    };
  },
  watch: {
    dialog(v) {
      if (v) {
        this.load();
      }
    }
  },
  methods: {
    load() {
      this.$store.api.user(this.user_id).then(user => {
        this.user = user;
      }).catch(this.setError);
    },
    del() {
      const { user } = this;
      if (!user) {
        return;
      }
      this.startSaving();
      this.$store.api.deleteUser({
        user_id: user.id
      }).then(() => {
        this.$store.change();
        this.dialog = false;
      }).catch(this.setError).then(this.stopSaving);
    }
  }
});
