<template>
<div>
  <v-snackbar
    :value="snackbar"
  >
    {{ $store.error }}
    <template v-slot:action>
      <v-btn
        color="red"
        text
        @click="$store.clearError"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</div>
</template>

<script>
export default {
  computed: {
    snackbar() {
      return !!this.$store.error;
    }
  }
}; //
</script>
