<template>
  <v-chip
    :color="color"
    :href="`/contracts?id=${contract.id}`"
    :title="title"
    class="chip-button"
    dark
    @click.prevent="onClick">
    <v-avatar
      class="white"
      left
      light
      style="color: #333"
    >
      <v-icon small>mdi-file-document-edit-outline</v-icon>
    </v-avatar>
    {{ formatDate(expirationDate) }} — {{ daysFromNow(expirationDate) }} days
  </v-chip>
</template>

<script>
export default {
  props: ['contract'],
  computed: {
    status() {
      return this.contract.status;
    },
    expirationDate() {
      return this.contract.expiration_date;
    },
    title() {
      return this.contract.license_type;
    },
    active() {
      return !!this.contract.activated_at;
    },
    color() {
      if (!this.active) {
        return 'gray';
      }

      if (this.contract.deactivated_at) {
        return 'black';
      }

      return !this.isDateExpired(this.expirationDate) ? 'light-green' : 'gray';
    }
  },
  methods: {
    onClick() {
      this.$store.navigate('/contracts', {
        id: this.contract.id
      });
    }
  }
};
</script>
