

















































import Vue, { PropType } from 'vue';
import { parseDate } from '@/helpers';
import DatePicker from '@/components/controls/DatePicker.vue';
import { Customer } from '@/types';

type Data = {
  dialog: boolean,
  customer: Customer | null,
  isSaving: boolean,
  attrs: {
    expiration_date: string | null,
    comment: string | null
  }
};

export default Vue.extend({
  components: { DatePicker },
  props: {
    customer_id: {
      type: Number as PropType<number>,
      required: true
    },
    order_id: {
      type: Number as PropType<number>,
      required: true
    }
  },
  data(): Data {
    return {
      dialog: false,
      customer: null,
      isSaving: false,
      attrs: {
        expiration_date: null,
        comment: null
      }
    };
  },
  watch: {
    customer_id: {
      handler() {
        this.loadCustomer();
      },
      immediate: true
    }
  },
  methods: {
    save() {
      const { order_id } = this;
      const { expiration_date, comment } = this.attrs;
      this.cleanError();
      this.startSaving();
      this.$store.api.createContractForRequest({
        order_id,
        expiration_date: expiration_date ? parseDate(expiration_date) : null,
        comment
      }).then(() => {
        this.$store.change();
        this.closeDialog();
      }).catch(this.setError).then(this.stopSaving);
    },
    closeDialog() {
      this.dialog = false;
      this.restoreInitialState();
    },
    loadCustomer() {
      this.$store.api.customer(this.customer_id).then(customer => {
        this.customer = customer;
      }).catch(this.setError);
    }
  }
});
