












































import Vue, {PropType} from 'vue';
import {Contract} from '@/types';

type Data = {
  dialog: boolean,
  error: Error | null
};

export default Vue.extend({
  props: {
    contract: {
      type: Object as PropType<Contract>,
      required: true
    }
  },
  data(): Data {
    return {
      dialog: false,
      error: null
    };
  },
  computed: {
    contract_id(): number {
      return this.$props.contract.id;
    },
    rejected_at(): string | null {
      return this.$props.contract.rejected_at;
    },
    buttonColor(): string {
      return (this.rejected_at ? 'warning' : 'primary') + ' lighten-2';
    }
  },
  methods: {
    save() {
      const {contract_id} = this;
      this.startSaving();
      this.$store.api.activateContract({
        contract_id
      }).then(() => {
        this.$store.change();
        this.dialog = false;
      }).catch(this.setError).then(this.stopSaving);
    }
  }
});
