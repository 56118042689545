





















































































import Vue from 'vue';
import StoreAlert from '@/components/StoreAlert.vue';
import {Contract, Customer, CustomerAggregate, CustomerEmail, SortOption, TableData, TableOptions} from '@/types';
import {debounce} from '@/helpers';

function defaultOptions() : TableOptions {
  return {
    sortBy: ['id'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 20
  };
}

export default Vue.extend({
  name: 'Customers',
  components: {StoreAlert},
  data(): TableData<Customer, CustomerAggregate> {
    return {
      headers: [
        {value: 'id', text: 'ID'},
        {value: 'name', text: 'Name'},
        {value: 'company_name', text: 'Company name'},
        {value: 'email', text: 'Email', sortable: false},
        {value: 'contracts', text: 'Last Contract'},
        {value: 'hash_count', text: 'Sample count'},
        {value: 'size_sum', text: 'Total size'},
        {value: 'ips_count', text: 'Ip count'},
        {value: 'runs_count', text: 'Runs'},
        {value: 'created_at', text: 'Created at'},
        {value: 'updated_at', text: 'Updated at'}
      ],
      items: [],
      options: defaultOptions(),
      aggregate: undefined,
      total: 0,
      expanded: []
    };
  },
  watch: {
    $hash() {
      this.$store.updateTableOptions(this.options, defaultOptions());
      this.load();
    }
  },
  computed: {
    sort(): SortOption[] {
      const {sortBy, sortDesc} = this.options;
      return (sortBy as string[]).map((column, i) => {
        return {
          column: `customers.${column}`,
          order: sortDesc[i] ? 'desc' : 'asc'
        };
      });
    }
  },
  methods: {
    lastContract(contracts: Contract[]) {
      return contracts.filter(c => c.kind === 'contract').pop();
    },
    emailProps(email: CustomerEmail) {
      const props = [];
      if (email.is_academic) {
        props.push('ac.');
      }
      if (!email.verified) {
        props.push('unconfirmed');
      }
      return props.length ? ('(' + props.join(',') + ')') : '';
    },
    updateOptions(options: TableOptions) {
      this.$store.setTableOptions(options, defaultOptions());
    },
    load() {
      this.cleanError();
      this.startLoading();

      const {page, itemsPerPage} = this.options;

      const where = Object.assign({}, this.$store.query) as Record<string, unknown>;

      if (typeof where.license_types === 'string') {
        where.license_types = where.license_types.split(',');
      }

      this.$store.api.customers({
        where,
        sort: this.sort,
        page,
        perPage: itemsPerPage
      }).then(({items, total, aggregate}) => {
        this.stopLoading();
        this.items = items;
        this.total = total;
        this.aggregate = aggregate;
      }).catch(this.setError);
    }
  },
  created() {
    this.$store.updateTableOptions(this.options, defaultOptions());
    this.load = debounce(this.load, 200, true);
    this.load();
  }
});
