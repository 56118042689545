




import Vue from 'vue';

export default Vue.extend({
  name: 'Pages',
  computed: {
    currentPage(): string {
      return this.$store.currentPage;
    }
  }
});
