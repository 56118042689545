<template>
  <div v-if="length" class="query-filters">
    <v-chip v-for="(f, i) in filters" :key="i" close @click:close="removeFilter(f.key)">
      <span class="field">{{f.key}}:</span> {{f.val}}
    </v-chip>
  </div>
</template>

<script>
export default {
  computed: {
    length() {
      return this.filters.length;
    },
    filters() {
      return Object.entries(this.$store.query).map(([key, val]) => {
        return {
          key,
          val
        };
      });
    }
  },
  methods: {
    removeFilter(key) {
      this.$store.removeFilter(key);
    }
  }
};
</script>
