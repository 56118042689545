


























































import Vue from 'vue';
import {isNumeric, tap} from '@/helpers';

type Data = {
  dialog: boolean,
  error: Error | null
};

export default Vue.extend({
  components: { },
  data(): Data {
    return {
      dialog: false,
      error: null
    };
  },
  computed: {
    filterId: {
      get(): string | null {
        return this.$store.getParam('id');
      },
      set(val: string) {
        if (isNumeric(val)) {
          this.$store.setParam('id', val);
        }
      }
    },
    filterMetricId: {
      get(): string | null {
        return this.$store.getParam('metric_id');
      },
      set(val: string) {
        this.$store.setParam('metric_id', val);
      }
    },
    filterSearch: {
      get(): string | null {
        return this.$store.getParam('search');
      },
      set(val: string) {
        this.$store.setParam('search', val);
      }
    },
    filterWhois: {
      get(): string | null {
        return this.$store.getParam('whois');
      },
      set(val: string) {
        this.$store.setParam('whois', val);
      }
    },
    filterHasActiveContract: {
      get(): boolean {
        return this.$store.getParam('active_contract') === 'true';
      },
      set(val: boolean) {
        this.$store.setParam('active_contract', val ? 'true' : '');
      }
    },
    filterHasSomeSamples: {
      get(): boolean {
        return this.$store.getParam('has_samples') === 'true';
      },
      set(val: boolean) {
        this.$store.setParam('has_samples', val ? 'true' : '');
      }
    },
    filterHasOffline: {
      get(): boolean {
        return this.$store.getParam('has_offline') === 'true';
      },
      set(val: boolean) {
        this.$store.setParam('has_offline', val ? 'true' : '');
      }
    },
    filterLicenseType: {
      get(): string {
        return this.$store.getParam('license_type');
      },
      set(val: string) {
        this.$store.setParam('license_type', val || '');
      }
    },
    filterLicenseTypes: {
      get(): string[] {
        return tap(this.$store.getParam('license_types'), v => v ? v.split(',') : []);
      },
      set(val: string[]) {
        this.$store.setParam('license_types', val.join(','));
      }
    }
  },
  methods: {
    save() {
      this.dialog = false;
    }
  }
});
