import Vue, {Component} from 'vue';
import {factoryApi} from '@/api';
import {Store, StoreData, TableOptions} from '@/types';
import {beforeStr, pathString, prependWith, queryString} from '@/helpers';
import Users from '@/pages/Users.vue';
import Customers from '@/pages/Customers.vue';
import ContractMails from '@/pages/ContractMails.vue';
import NotFound from '@/pages/NotFound.vue';
import CustomerEmails from '@/pages/CustomerEmails.vue';
import Contracts from '@/pages/Contracts.vue';
import VerifyToken from '@/pages/VerifyToken.vue';
import Main from '@/pages/Main.vue';
import ConfirmationMails from '@/pages/ConfirmationMails.vue';
import History from '@/pages/History.vue';
import Ips from '@/pages/Ips.vue';

const routes = [{
  path: '/main',
  component: Main
}, {
  path: '/contracts',
  component: Contracts
}, {
  path: '/',
  component: Customers
}, {
  path: '/customers',
  component: Customers
}, {
  path: '/customer-emails',
  component: CustomerEmails
}, {
  path: '/contract-mails',
  component: ContractMails
}, {
  path: '/confirmation-mails',
  component: ConfirmationMails
}, {
  path: '/history',
  component: History
}, {
  path: '/ips',
  component: Ips
}, {
  path: '/tasks',
  component: () => import('@/pages/Tasks.vue')
}, {
  path: '/verify-token',
  component: VerifyToken
}, {
  path: '/athena',
  component: () => import('@/pages/Athena.vue')
}, {
  path: '/users',
  component: Users
}];

function sortString(options: TableOptions) {
  return options.sortBy.map((field, i) => {
    return field + (options.sortDesc[i] ? ':desc' : '');
  }).join(',');
}

export default {
  install(V: typeof Vue) {
    V.prototype.$store = new Vue({
      data(): StoreData {
        return {
          notifications: [],
          counter: 0,
          url: prependWith(location.pathname + location.search, '/'),
          drawer: false,
          token: null,
          api: factoryApi(this as unknown as Store),
          error: null,
          info: {
            orders: 0
          }
        };
      },
      watch: {
        token(v) {
          if (v) {
            this.loadInfo();
          }
        }
      },
      computed: {
        hash(): string {
          return this.url + ':' + this.counter;
        },
        queryString(): string {
          return queryString(this.url);
        },
        path(): string {
          return pathString(this.url);
        },
        searchParams(): URLSearchParams {
          return new URLSearchParams(this.queryString);
        },
        query(): Record<string, string | number> {
          const entries = this.searchParams.entries();
          const result = {} as Record<string, any>;
          for (const [key, value] of entries) {
            result[key] = value;
          }
          return result;
        },
        currentPage(): Component {
          const p = beforeStr(this.path, '?');
          return routes.find(r => {
            return p === r.path;
          })?.component || NotFound;
        }
      },
      methods: {
        clearError() {
          this.error = null;
        },
        createParams(query: Record<string, any>): URLSearchParams {
          const params = new URLSearchParams();
          Object.keys(query).forEach(key => {
            params.set(key, query[key]);
          });
          return params;
        },
        createUrl(url: string, query: Record<string, any> | URLSearchParams = {}) {
          const params = query instanceof URLSearchParams ? query : this.createParams(query);
          const queryString = params.toString();
          return prependWith(url, '/') + (queryString ? `?${queryString}` : '');
        },
        navigate(url: string, query: Record<string, any> | URLSearchParams = {}) {
          const params = query instanceof URLSearchParams ? query : this.createParams(query);
          const queryString = params.toString();
          this.url = prependWith(url, '/') + (queryString ? `?${queryString}` : '');
          window.history.pushState({}, this.url, this.url);
        },
        changeQuery(query: Record<string, any>) {
          const params = this.createParams(query);
          this.navigate(this.path + '?' + params.toString());
        },
        getParam(name: string) {
          const params = this.searchParams;
          return params.get(name);
        },
        getParamNumber(name: string) {
          const v = Number(this.getParam(name));
          return Number.isNaN(v) ? undefined : v;
        },
        setParam(name: string, val: string) {
          const params = this.searchParams;
          if (val === '' || val === null) {
            params.delete(name);
          } else {
            params.set(name, val);
          }
          this.navigate(this.path + '?' + params.toString());
        },
        setTableOptions(options: TableOptions, defaults: TableOptions) {
          const {page, itemsPerPage} = options;
          const sort = sortString(options);

          const initial = this.searchParams;
          const params = new URLSearchParams(this.queryString);

          if (page !== defaults.page) {
            params.set('page', page + '');
          } else {
            params.delete('page');
          }

          if (itemsPerPage !== defaults.itemsPerPage) {
            params.set('per-page', itemsPerPage + '');
          } else {
            params.delete('per-page');
          }

          if (sort && (sort !== sortString(defaults))) {
            params.set('sort', sort);
          } else {
            params.delete('sort');
          }

          if (initial.toString() !== params.toString()) {
            this.navigate(this.path, params);
          }
        },
        updateTableOptions(options: TableOptions, defaults: TableOptions) {
          const params = this.searchParams;

          if (params.has('page')) {
            options.page = Number(params.get('page'));
          } else {
            options.page = defaults.page;
          }

          if (params.has('per-page')) {
            options.itemsPerPage = Number(params.get('per-page'));
          } else {
            options.itemsPerPage = defaults.itemsPerPage;
          }

          if (params.has('sort')) {
            options.sortBy = [];
            options.sortDesc = [];
            params.get('sort')!.split(',').forEach(v => {
              const [field, desc] = v.split(':');
              options.sortBy.push(field);
              options.sortDesc.push(desc === 'desc');
            });
          } else {
            options.sortBy = defaults.sortBy;
            options.sortDesc = defaults.sortDesc;
          }
        },
        flushToken() {
          this.token = null;
          localStorage.removeItem('token');
          location.reload();
        },
        removeFilter(key: string) {
          const params = this.searchParams;
          params.delete(key);
          const queryString = params.toString();
          this.navigate(this.path + (queryString ? '?' + queryString : ''));
        },
        change() {
          this.counter++;
          this.loadInfo();
        },
        loadInfo() {
          this.api.info().then(info => {
            this.info = info;
          }).catch(console.error);
        }
      },
      created() {
        window.onpopstate = (event: PopStateEvent) => {
          this.url = location.pathname + location.search;
        };
      }
    });
  }
};
