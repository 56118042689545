




















































































































import Vue, {PropType} from 'vue';
import {Customer} from '@/types';
import EditCustomer from '@/components/actions/EditCustomer.vue';
import DeleteCustomer from '@/components/actions/DeleteCustomer.vue';
import RevokeAcademicStatus from '@/components/actions/RevokeAcademicStatus.vue';

export default Vue.extend({
  components: {RevokeAcademicStatus, DeleteCustomer, EditCustomer},
  props: {
    id: {
      type: Number as PropType<number>,
      required: true
    }
  },
  data(): { customer: Customer | null } {
    return {
      customer: null
    };
  },
  watch: {
    $hash() {
      this.load();
    }
  },
  computed: {
    isAcademic(): boolean {
      return !!(this?.customer?.email?.is_academic);
    },
    metricsUrl(): string {
      if (!this.customer) {
        return '#';
      }

      return this.$store.createUrl('/athena', {
        sql: `select *
from v_runs
where client_id = '${this.customer.metric_id}'
order by created_at desc;`
      });
    },
    distinctAppsUrl(): string {
      if (!this.customer) {
        return '#';
      }

      return this.$store.createUrl('/athena', {
        sql: `select app_id, count(distinct run) as run_count, max(created_at) as last_date
from v_runs
where client_id = '${this.customer.metric_id}'
group by app_id
order by last_date desc;`
      });
    },
    presetsUrl(): string {
      if (!this.customer) {
        return '#';
      }

      return this.$store.createUrl('/athena', {
        sql: `select *
        from v_runs
        where client_id = '${this.customer.metric_id}'
        and preset is not null
        order by created_at desc;`
      });
    }
  },
  methods: {
    load() {
      this.$store.api.customer(this.id).then(customer => {
        this.customer = customer;
      }).catch(this.setError);
    }
  },
  mounted() {
    this.load();
  }
});
