var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('v-col',{staticClass:"mb-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('store-alert'),_c('div',{staticClass:"table-container"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":0,"footer-props":_vm.defaultFooterProps,"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.isLoading,"fixed-header":"","show-expand":""},on:{"update:options":[function($event){_vm.options=$event},_vm.updateOptions]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"panel"},[_c('div',{staticClass:"title"},[_c('v-icon',[_vm._v("mdi-mail")]),_vm._v(" Contract mails ")],1),_c('div',{staticClass:"spacer"}),_c('query-filters'),_c('contract-mails-filter')],1)]},proxy:true},{key:"item.contract",fn:function(ref){
var item = ref.item;
return [_c('contract-chip',{attrs:{"contract":item.contract}})]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email.email)+" ")]}},{key:"item.sent_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.sent_at))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"mail-box"},[_vm._v(_vm._s(item.text))])])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }