
































































































































import Vue, { PropType } from 'vue';
import { Contract } from '@/types';
import ActivateContract from '@/components/actions/ActivateContract.vue';
import ReactivateContract from '@/components/actions/ReactivateContract.vue';
import RejectOrder from '@/components/actions/RejectOrder.vue';

export default Vue.extend({
  components: { RejectOrder, ReactivateContract, ActivateContract },
  props: {
    id: {
      type: Number as PropType<number>,
      required: true
    }
  },
  data(): {contract: Contract | null} {
    return {
      contract: null
    };
  },
  watch: {
    $hash() {
      this.load();
    }
  },
  computed: {
    licenseLink(): string {
      const { contract } = this;
      if (!contract || contract.kind !== 'contract' || !contract?.link_hash) {
        return '';
      }
      return (process.env.VUE_APP_SITE_URL || 'https://licensing.milaboratories.com') + '/license/' + contract?.link_hash;
    }
  },
  methods: {
    load() {
      this.$store.api.contract(this.id).then(contract => {
        this.contract = contract;
      }).catch(this.setError);
    }
  },
  mounted() {
    this.load();
  }
});
