<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    min-width="auto"
    offset-y
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :error-messages="computedErrors"
        :label="label"
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        @click:prepend="on.click"
      />
    </template>
    <v-date-picker
      v-model="pickerDate"
      scrollable
    >
      <v-spacer/>
      <v-btn
        color="primary"
        text
        @click="menu = false"
      >
        Cancel
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="$refs.menu.save(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { formatDateDP, isValidDate, parseDate } from '@/helpers';

export default {
  props: ['value', 'label', 'errorMessages'],
  data() {
    return {
      error: null,
      menu: false
    };
  },
  computed: {
    computedErrors() {
      return [this.error].concat(this.errorMessages || []).filter(v => !!v);
    },
    pickerDate: {
      get() {
        return this.value ? formatDateDP(this.value) : this.value;
      },
      set(v) {
        this.error = null;

        if (!v) {
          return this.$emit('input', null);
        }

        const date = parseDate(v);

        if (isValidDate(date)) {
          this.$emit('input', v);
        } else {
          this.error = 'Invalid date';
        }

        this.$emit('input', v);
      }
    },
    date: {
      get() {
        return this.value;
      },
      set(v) {
        this.error = null;

        if (!v) {
          return this.$emit('input', null);
        }

        const date = parseDate(v);

        if (isValidDate(date)) {
          this.$emit('input', v);
        } else {
          this.error = 'Invalid date';
        }

        this.$emit('input', v);
      }
    }
  }
};
</script>
