




























































import Vue from 'vue';
import StoreAlert from '@/components/StoreAlert.vue';
import {Ip, SortOption, TableData, TableOptions} from '@/types';
import IpsFilter from '@/components/filters/IpsFilter.vue';
import {debounce, filterUndefKeys, isNumeric} from '@/helpers';

function defaultOptions() : TableOptions {
  return {
    sortBy: ['id'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 20
  };
}

export default Vue.extend({
  name: 'Ips',
  components: {IpsFilter, StoreAlert},
  data(): TableData<Ip> {
    return {
      headers: [
        {value: 'id', text: 'ID'},
        {value: 'ip', text: 'IP'},
        {value: 'customers', text: 'Customers'},
        {value: 'created_at', text: 'Created at'},
        {value: 'updated_at', text: 'Updated at'}
      ],
      items: [],
      options: defaultOptions(),
      total: 0,
      expanded: []
    };
  },
  watch: {
    $hash() {
      this.$store.updateTableOptions(this.options, defaultOptions());
      this.load();
    }
  },
  computed: {
    hasFilter(): boolean {
      return Object.keys(this.$store.query).length > 0;
    },
    isCard(): boolean {
      return this.hasFilter && this.items.length === 1;
    },
    sort(): SortOption[] {
      const {sortBy, sortDesc} = this.options;
      return (sortBy as string[]).map((column, i) => {
        return {
          column,
          order: sortDesc[i] ? 'desc' : 'asc'
        };
      });
    }
  },
  methods: {
    loadWhois(id: number) {
      this.$store.api.whois(id).then(res => {
        const it = this.items.find(it => it.id === id);
        if (it) {
          it.whois = res;
        }
      });
    },
    updateOptions(options: TableOptions) {
      this.$store.setTableOptions(options, defaultOptions());
    },
    load() {
      this.cleanError();
      this.startLoading();
      const {page, itemsPerPage} = this.options;
      const where = this.$store.query;
      this.$store.api.ips({
        where: filterUndefKeys({
          ip: where.ip,
          customer_id: isNumeric(where.customer_id) ? Number(where.customer_id) : undefined,
          search: where.search
        }),
        sort: this.sort,
        page,
        perPage: itemsPerPage
      }).then(({items, total}) => {
        this.stopLoading();
        this.items = items;
        this.total = total;
      }).catch(this.setError);
    }
  },
  created() {
    this.$store.updateTableOptions(this.options, defaultOptions());
    this.load = debounce(this.load, 200, true);
    this.load();
  }
});
