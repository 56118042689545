















































import Vue from 'vue';
import {isNumeric} from '@/helpers';

type Data = {
  dialog: boolean,
  error: Error | null
};

export default Vue.extend({
  components: { },
  data(): Data {
    return {
      dialog: false,
      error: null
    };
  },
  computed: {
    filterIp: {
      get(): string | number | null {
        return this.$store.getParam('ip');
      },
      set(val: string) {
        this.$store.setParam('ip', val);
      }
    },
    filterCustomerId: {
      get(): string | null {
        return this.$store.getParam('customer_id');
      },
      set(val: string) {
        if (isNumeric(val)) {
          this.$store.setParam('customer_id', val);
        }
      }
    },
    filterSearch: {
      get(): string | null {
        return this.$store.getParam('search');
      },
      set(val: string) {
        this.$store.setParam('search', val);
      }
    }
  },
  methods: {
    save() {
      this.dialog = false;
    }
  }
});
