























import Vue from 'vue';
import LayoutAuth from '@/components/LayoutAuth.vue';

export default Vue.extend({
  name: 'Auth',
  components: { LayoutAuth }
});
