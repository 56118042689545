<template>
  <span v-if="value" class="string">{{value}}</span>
  <span v-else-if="value === ''" class="string empty">Empty</span>
  <span v-else class="string null">NULL</span>
</template>

<script>
export default {
  props: ['value']
};
</script>
