import axios from 'axios';
import {
  Contract,
  ContractMail,
  Customer,
  CustomerEmail,
  SortOption,
  Store,
  Info,
  ConfirmationMail,
  History,
  Ip,
  Athena,
  LICENSE_TYPE,
  CustomerAggregate,
  User,
  Task
} from '@/types';
import {HttpError, NetworkError} from '@/errors';

// eslint-disable-next-line camelcase
type Pagination<T = { id: number, created_at: string, updated_at: string }, A = unknown> = {
  headers: { value: string, text: string }[];
  items: T[];
  total: number;
  aggregate: A;
};

type PaginationParams<F = Record<string, string | number | boolean>> = {
  where?: F,
  sort?: SortOption[],
  page: number,
  perPage: number
};

export function factoryApi(options: Store) {
  axios.interceptors.request.use(function (config) {
    config.baseURL = process.env.VUE_APP_API_URL;
    config.headers || (config.headers = {});
    config.headers.Authorization = options.token || '';
    return config;
  });

  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response) {
      const {status} = error.response;
      const data = error.response.data;
      const message = typeof data === 'string' ? data : data?.message;
      const errors = (data && typeof data === 'object') ? data?.errors || [] : [];
      throw new HttpError(status, error.message + ': ' + message, errors);
    } else {
      return Promise.reject(new NetworkError(error.message));
    }
  });

  function post<P>(url: string, params: P) {
    return axios.post(url, params).then(r => r.data);
  }

  return {
    athena: {
      getQueryExecutionId(QueryString: string): Promise<{ QueryExecutionId: string }> {
        return post('admin/athena/get-query-execution-id', {
          QueryString
        });
      },
      awaitExecution(QueryExecutionId: string): Promise<unknown> {
        return post('admin/athena/await-execution', {
          QueryExecutionId
        });
      },
      getQueryResults(QueryExecutionId: string, NextToken?: string): Promise<Athena.GetQueryResultsOutput> {
        return post('admin/athena/get-query-results', {
          QueryExecutionId,
          NextToken
        });
      }
    },
    verify(token: string): unknown {
      return post('verify-token', {
        token
      });
    },
    appsStatus() {
      return post('admin/apps-status', {});
    },
    info(): Promise<Info> {
      return post('admin/info', {});
    },
    users(params: PaginationParams<{
      id?: number;
      metric_id?: string;
      search?: string;
    }>): Promise<Pagination<User>> {
      return post('admin/users', params);
    },
    user(id: number): Promise<User> {
      return this.users({where: {id}, page: 1, perPage: 1}).then(r => {
        return r.items[0];
      });
    },
    customers(params: PaginationParams<{
      id?: number;
      metric_id?: string;
      search?: string;
    }>): Promise<Pagination<Customer, CustomerAggregate>> {
      return post('admin/customers', params);
    },
    customer(id: number): Promise<Customer> {
      return this.customers({where: {id}, page: 1, perPage: 1}).then(r => {
        return r.items[0];
      });
    },
    customerEmails(params: PaginationParams): Promise<Pagination<CustomerEmail>> {
      return post('admin/customer-emails', params);
    },
    contracts(params: PaginationParams): Promise<Pagination<Contract>> {
      return post('admin/contracts', params);
    },
    history(params: PaginationParams): Promise<Pagination<History>> {
      return post('admin/history', params);
    },
    ips(params: PaginationParams): Promise<Pagination<Ip>> {
      return post('admin/ips', params);
    },
    tasks(params: PaginationParams): Promise<Pagination<Task>> {
      return post('admin/tasks', params);
    },
    updateStats(): Promise<Pagination<Task>> {
      return post('admin/update-stats', {});
    },
    whois(id: number): Promise<string> {
      return post('admin/whois', {
        id
      });
    },
    contract(id: number): Promise<Contract> {
      return post('admin/contracts', {where: {id}, page: 1, perPage: 1}).then(r => {
        return r.items[0];
      });
    },
    contractMails(params: PaginationParams): Promise<Pagination<ContractMail>> {
      return post('admin/contract-mails', params);
    },
    confirmationMails(params: PaginationParams): Promise<Pagination<ConfirmationMail>> {
      return post('admin/confirmation-mails', params);
    },
    createUser(attrs: {
      name: string | null;
      email: string | null;
      roles: string[];
    }) {
      return post('admin/create-user', attrs);
    },
    editUser(attrs: {
      user_id: number;
      name: string | null;
      roles: string[];
    }) {
      return post('admin/edit-user', attrs);
    },
    deleteUser(attrs: {
      user_id: number;
    }) {
      return post('admin/delete-user', attrs);
    },
    createCustomer(attrs: {
      name: string | null;
      company_name?: string | null;
      email: string | null;
      comment?: string | null;
      expiration_text?: string | null;
    }) {
      return post('admin/create-customer', attrs);
    },
    editCustomer(attrs: {
      customer_id: number;
      name: string | null;
      company_name?: string | null;
      comment?: string | null;
      expiration_text?: string | null;
    }) {
      return post('admin/edit-customer', attrs);
    },
    deleteCustomer(attrs: {
      customer_id: number;
      guard: string;
    }) {
      return post('admin/delete-customer', attrs);
    },
    revokeAcademicStatus(attrs: {
      customer_id: number;
      guard: string;
    }) {
      return post('admin/revoke-academic-status', attrs);
    },
    createContract(attrs: {
      customer_id: number | null;
      comment?: string | null;
      issue_date?: Date | null;
      expiration_date: Date | null;
      warning_date?: Date | null;
      warning_text?: string | null;
      license_ttl?: number | null;
      license_type?: LICENSE_TYPE;
      hw?: string | null;
      f?: Record<string, string> | null;
    }) {
      return post('admin/create-contract', attrs);
    },
    createContractForRequest(attrs: {
      order_id: number | null;
      expiration_date: Date | null,
      comment: string | null
    }) {
      return post('admin/create-contract-for-request', attrs);
    },
    activateContract(attrs: {
      contract_id: number
    }) {
      return post('admin/activate-contract', attrs);
    },
    rejectOrder(attrs: {
      contract_id: number
    }) {
      return post('admin/reject-order', attrs);
    },
    reactivateContract(attrs: {
      contract_id: number
    }) {
      return post('admin/reactivate-contract', attrs);
    },
    deactivateContract(attrs: {
      contract_id: number
    }) {
      return post('admin/deactivate-contract', attrs);
    },
    editContract(attrs: {
      contract_id: number;
      license_ttl: number | null;
      license_type?: LICENSE_TYPE;
      comment: string | null;
      issue_date: Date;
      expiration_date: Date;
      warning_date: Date | null;
      warning_text: string | null;
      hw: string | null;
      f: Record<string, string> | null;
    }) {
      return post('admin/edit-contract', attrs);
    },
    getContractLicense(attrs: {
      contract_id: number;
      overrideIssueDate?: string;
      overrideExpirationDate?: string;
    }) {
      return post('admin/get-contract-license', attrs);
    }
  };
}

export type Api = ReturnType<typeof factoryApi>;
