var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('v-col',{staticClass:"mb-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('store-alert'),_c('div',{staticClass:"table-container"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":0,"expanded":_vm.expanded,"footer-props":_vm.defaultFooterProps,"headers":_vm.headers,"item-class":_vm.itemClass,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.isLoading,"fixed-header":"","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":[function($event){_vm.options=$event},_vm.updateOptions]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"panel"},[_c('div',{staticClass:"title"},[_c('v-icon',[_vm._v("mdi-key")]),_vm._v(" Contracts ")],1),_c('div',{staticClass:"spacer"}),_c('query-filters'),_c('contracts-filter'),_c('create-contract')],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.deactivated_at)?_c('v-chip',{attrs:{"color":"grey darken-4","text-color":"white","x-small":""}},[_vm._v(" deactivated ")]):(item.rejected_at)?_c('v-chip',{attrs:{"color":"error","x-small":""}},[_vm._v(" rejected ")]):(_vm.isDateExpired(item.expiration_date))?_c('v-chip',{attrs:{"color":"gray","x-small":""}},[_vm._v(" expired ")]):(item.activated_at)?_c('v-chip',{attrs:{"color":"success","x-small":""}},[_vm._v(" active ")]):_c('v-chip',{attrs:{"color":"warning","x-small":""}},[_vm._v(" new order ")])]}},{key:"item.kind",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.kind))])]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_c('customer-chip',{attrs:{"customer":item.customer}})]}},{key:"item.issue_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.issue_date))+" ")]}},{key:"item.expiration_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.expiration_date))+" ")]}},{key:"item.confirmed_at",fn:function(ref){
var item = ref.item;
return [_c('date',{attrs:{"value":item.confirmed_at}},[_c('span',{staticClass:"error--text"},[_vm._v("UNCONFIRMED")])])]}},{key:"item.activated_at",fn:function(ref){
var item = ref.item;
return [_c('date',{attrs:{"value":item.activated_at}})]}},{key:"item.deactivated_at",fn:function(ref){
var item = ref.item;
return [_c('date',{attrs:{"value":item.deactivated_at}})]}},{key:"item.rejected_at",fn:function(ref){
var item = ref.item;
return [_c('date',{attrs:{"value":item.rejected_at}})]}},{key:"item.warning_date",fn:function(ref){
var item = ref.item;
return [_c('date',{attrs:{"value":item.warning_date}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('date',{attrs:{"value":item.created_at}})]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('date',{attrs:{"value":item.updated_at}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('contract-card',{attrs:{"id":item.id}})],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }