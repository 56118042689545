var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('v-col',{staticClass:"mb-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('store-alert'),_c('div',{staticClass:"table-container"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":0,"footer-props":_vm.defaultFooterProps,"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.isLoading,"fixed-header":""},on:{"update:options":[function($event){_vm.options=$event},_vm.updateOptions]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"panel"},[_c('div',{staticClass:"title"},[_c('v-icon',[_vm._v("mdi-at")]),_vm._v(" Emails ")],1),_c('div',{staticClass:"spacer"}),_c('query-filters'),_c('customer-emails-filter')],1)]},proxy:true},{key:"item.is_academic",fn:function(ref){
var item = ref.item;
return [_c('boolean',{attrs:{"value":item.is_academic}})]}},{key:"item.verified",fn:function(ref){
var item = ref.item;
return [_c('boolean',{attrs:{"value":item.verified}})]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at))+" ")]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }