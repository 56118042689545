import { formatISO, format, parseISO } from 'date-fns';

export function tap<T, R>(v: T, cb: (v: T) => R) {
  return cb(v);
}

export function prependWith(str: string, p: string) {
  return str.startsWith(p) ? str : p + str;
}

export function beforeStr(str: string, ch: string) {
  const index = str.indexOf(ch);
  if (index > -1) {
    return str.slice(0, index);
  }
  return str;
}

export function queryString(str: string) {
  const index = str.indexOf('?');
  if (index > -1) {
    return str.slice(index, str.length);
  }
  return '';
}

export function pathString(str: string) {
  const index = str.indexOf('?');
  if (index > -1) {
    return str.slice(0, index);
  }
  return str;
}

export function parseDate(date: Date | string): Date {
  if (date instanceof Date) {
    return date;
  }

  if (date.length < 10) {
    return new Date(NaN);
  }

  return parseISO(date);
}

export function secondsLeft(_date: Date | string) {
  const date = parseDate(_date);

  return (date.getTime() - new Date().getTime()) / 1000;
}

export function formatDate(date: Date | string) {
  if (typeof date === 'string') {
    date = parseISO(date);
  }

  return format(date, 'yyyy-MM-dd hh:mm:ss');
}

export function formatDateDP(date: Date | string) {
  if (typeof date === 'string') {
    date = parseISO(date);
  }

  if (!isValidDate(date)) {
    return null;
  }

  return format(date, 'yyyy-MM-dd');
}

export function parseDateIfNotEmpty(date: Date | string | null | undefined): Date | null {
  if (date === null || date === undefined) {
    return null;
  }

  return parseDate(date);
}

export function isValidDate(date: Date) {
  return !isNaN(date.getTime());
}

export function secondsToIsoDate(seconds: number) {
  return formatISO(new Date(seconds * 1000));
}

export function isNumeric(num: number | string) {
  return !Number.isNaN(Number(num));
}

export function filterUndefKeys<V>(obj: Record<string, V | undefined>): Record<string, V> {
  return Object.keys(obj).reduce((r, key) => {
    if (obj[key] !== undefined) {
      // @ts-ignore
      r[key] = obj[key];
    }
    return r;
  }, {} as Record<string, V>);
}

export function debounce<F extends (...args: unknown[]) => void>(callback: F, ms: number, immediate?: boolean): (...args: Parameters<F>) => void {
  let timeout: NodeJS.Timeout | undefined;
  return function (this: unknown, ...args: Parameters<F>) {
    const i = immediate && !timeout;
    i && callback.apply(this, args);
    timeout && clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = undefined;
      !i && callback.apply(this, args);
    }, ms);
  };
}
