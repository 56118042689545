


















































import Vue, { PropType } from 'vue';
import { Customer } from '@/types';

type Data = {
  customer: Customer | null;
  guard: string;
  dialog: boolean;
  error: Error | null;
};

export default Vue.extend({
  props: {
    customer_id: {
      type: Number as PropType<number>,
      required: true
    }
  },
  data(): Data {
    return {
      customer: null,
      guard: '',
      dialog: false,
      error: null
    };
  },
  watch: {
    dialog(v) {
      if (v) {
        this.load();
      }
    }
  },
  computed: {
    guardLabel(): string {
      return `Print "revoke academic ${this.customer_id}"`;
    }
  },
  methods: {
    load() {
      this.$store.api.customer(this.customer_id).then(customer => {
        this.customer = customer;
        this.guard = `revoke academic ${this.customer_id}`;
      }).catch(this.setError);
    },
    del() {
      const { customer } = this;
      if (!customer) {
        return;
      }
      this.startSaving();
      this.$store.api.revokeAcademicStatus({
        customer_id: customer.id,
        guard: this.guard
      }).then(() => {
        this.$store.change();
        this.dialog = false;
      }).catch(this.setError).then(this.stopSaving);
    }
  }
});
