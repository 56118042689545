





















































import Vue from 'vue';
import { isNumeric } from '@/helpers';

type Data = {
  dialog: boolean,
  error: Error | null,
  isAcademicOptions: { value: string, text: string }[]
};

export default Vue.extend({
  components: {},
  data(): Data {
    return {
      dialog: false,
      error: null,
      isAcademicOptions: [{
        value: 'false',
        text: 'Not academic'
      }, {
        value: 'true',
        text: 'Academic'
      }]
    };
  },
  computed: {
    filterId: {
      get(): string | null {
        return this.$store.getParam('id');
      },
      set(val: string) {
        if (isNumeric(val)) {
          this.$store.setParam('id', val);
        }
      }
    },
    filterEmail: {
      get(): string | null {
        return this.$store.getParam('email');
      },
      set(val: string) {
        this.$store.setParam('email', val);
      }
    },
    filterIsAcademic: {
      get(): string | null {
        return this.$store.getParam('is_academic');
      },
      set(val: string) {
        this.$store.setParam('is_academic', val);
      }
    }
  },
  methods: {
    save() {
      this.dialog = false;
    }
  }
});
