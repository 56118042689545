





















































































import Vue, { PropType } from 'vue';
import { Contract } from '@/types';
import { parseDate, parseDateIfNotEmpty } from '@/helpers';
import JsonArea from '@/components/controls/JsonArea.vue';

type Data = {
  contract: Contract | null;
  dialog: boolean;
  error: Error | null;
};

export default Vue.extend({
  components: { JsonArea },
  props: {
    contract_id: {
      type: Number as PropType<number>,
      required: true
    }
  },
  data(): Data {
    return {
      contract: null,
      dialog: false,
      error: null
    };
  },
  watch: {
    dialog(v) {
      if (v) {
        this.load();
      } else {
        this.restoreInitialState();
      }
    }
  },
  methods: {
    load() {
      this.$store.api.contract(this.contract_id).then(contract => {
        this.contract = contract;
      }).catch(this.setError);
    },
    save() {
      const { contract } = this;
      if (!contract) {
        return;
      }
      this.startSaving();
      this.$store.api.editContract({
        contract_id: contract.id,
        license_ttl: Number(contract.license_ttl),
        license_type: contract.license_type || undefined,
        issue_date: parseDate(contract.issue_date),
        expiration_date: parseDate(contract.expiration_date),
        warning_date: parseDateIfNotEmpty(contract.warning_date),
        warning_text: contract.warning_text,
        comment: contract.comment || null,
        hw: contract.hw || null,
        f: contract.f || null
      }).then(() => {
        this.$store.change();
        this.dialog = false;
      }).catch(this.setError).then(this.stopSaving);
    }
  }
});
