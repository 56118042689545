
























import Vue from 'vue';
import { Customer } from '@/types';

type Data = {
  customer: Customer | null;
  items: Customer[],
  isLoading: boolean,
  search: string | null
}

export default Vue.extend({
  props: ['value', 'errorMessages'],
  data(): Data {
    return {
      customer: null,
      items: [],
      isLoading: false,
      search: null
    };
  },
  computed: {
    selectedId(): number | null {
      return this.value ? Number(this.value) : null;
    }
  },
  watch: {
    search() {
      this.load();
    }
  },
  methods: {
    async load() {
      this.isLoading = true;
      this.$store.api.customers({
        where: {
          search: this.search || undefined
        },
        page: 1,
        perPage: 10
      }).then(res => {
        this.items = res.items;
        if (this.selectedId) {
          return this.$store.api.customer(this.selectedId).then(customer => {
            this.items.unshift(customer);
          });
        }
      }).catch(this.setError).then(() => { this.isLoading = false; });
    },
    removeSelected() {
      this.$emit('input', null);
    },
    onInput(ev: unknown) {
      this.$emit('input', ev);
    }
  },
  mounted() {
    this.load();
  }
});
