<template>
  <v-chip
    v-if="customer"
    :close="close"
    :color="color"
    :href="`/customers?id=${customer.id}`"
    :title="title"
    class="chip-button"
    dark
    @click.prevent="onClick"
    @click:close="onClose"
  >
    <v-avatar
      class="white"
      left
      light
      style="color: #333"
    >
      <v-icon dark small>mdi-account</v-icon>
    </v-avatar>
    <div class="username">
      <div>
        {{ customer.name }}
        <span v-if="customer.company_name">
          ({{ customer.company_name }})
        </span>
      </div>
      <div v-if="customer.email" class="email">
        {{ customer.email.email }}
        <span v-if="customer.email.is_academic">(ac.)</span>
      </div>
    </div>
  </v-chip>
</template>

<script>
export default {
  props: {
    customer: Object,
    noClick: Boolean,
    close: Boolean
  },
  computed: {
    title() {
      return `id: ${this.customer.id}`;
    },
    color() {
      if (this.customer.email && !this.customer.email.verified) {
        return '#333';
      }

      return 'primary';
    }
  },
  methods: {
    onClick() {
      if (this.noClick) {
        return;
      }

      this.$store.navigate('/customers', {
        id: this.customer.id
      });
    },
    onClose() {
      this.$emit('close', this.customer.id);
    }
  }
};
</script>
